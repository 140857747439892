import React from "react"
import MediaQuery from 'react-responsive'
import Media from 'react-media'

const defaultState = {
  isMobile: false,
}

const MediaQueryContext = React.createContext(defaultState)

class MediaQueryProvider extends React.Component {
//   state = {
//     dark: false,
//   }

//   componentDidMount() {
//     // Getting dark mode value from localStorage!
//     const lsDark = JSON.parse(localStorage.getItem("dark"))
//     if (lsDark) {
//       this.setState({ dark: lsDark })
//     } else if (supportsDarkMode()) {
//       this.setState({ dark: true })
//     }
//   }

  render() {
    const { children } = this.props
    // const { dark } = this.state
    return (

          <Media queries={{
        mobile: "(max-width: 767px)",
        // small: "(max-width: 599px)",
        // medium: "(min-width: 600px) and (max-width: 1199px)",
        // large: "(min-width: 1200px)"
      }}>
        {matches => (
            <MediaQueryContext.Provider
                value={{
                    isMobile: matches.mobile
                }}
            >
                {children}
            </MediaQueryContext.Provider>
        )}
    </Media>
    )
  }
}

export default MediaQueryContext

export { MediaQueryProvider }