/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "antd/dist/antd.css"
import React from 'react'
import { MediaQueryProvider } from "./src/contexts/MediaQueryContext"

export const wrapRootElement = ({ element }) => {
  return (
    <MediaQueryProvider>
        {element}
    </MediaQueryProvider>
  )
}
